import { Business, Cash, Coins, Earn, School } from "assets"
import React, { Ref, RefObject, useRef } from "react"
import Feature from "./Feature"
import classes from "styles/components/Features.module.scss"
import ReactVisibilitySensor from "react-visibility-sensor"
import { useState } from "react"
import useScrollPosition from "hooks/useScrollPosition"
import { useBoundingclientrect, useBoundingclientrectRef } from "rooks"
import { useMemo } from "react"

const features = [
  {
    Icon: School,
    title: "Support your city & local schools.",
    description:
      "Everytime you buy or sell locally using nearcoins, your city is donated a small amount that can be used to fund public arts, local schools, and local citywide events.",
  },
  {
    Icon: Business,
    title: "Support Local Business.",
    description:
      "Earn rewards just for using Nearcast, while earning nearcoins that can be spent on restaurants.  ",
  },
  {
    Icon: Earn,
    title: "Earn on nearcast.",
    description:
      "You can earn Nearcoins by creating content and enriching your community on the Nearcast social network.",
  },
  {
    Icon: Cash,
    title: "Easily convert to cash.",
    description:
      "Nearcoins are quickly and easily converted to United States dollars through the Nearcoin.com website.",
  },
]

export default function Features() {
  const [myRef, rect] = useBoundingclientrectRef()
  const scrolled = useScrollPosition()
  const isVisible = useMemo(
    () => rect && scrolled >= (rect.top || 700) - window.innerHeight,
    [scrolled, rect]
  )
  return (
    <div
      ref={myRef}
      className={[
        classes.container,
        isVisible ? classes.visible : classes.invisible,
      ].join(" ")}
    >
      <div className={classes.features}>
        {features.map((feature) => (
          <Feature {...feature} />
        ))}
      </div>
      <div className={classes.image}>
        <Coins />
      </div>
    </div>
  )
}
