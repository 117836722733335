import {
  AppQR,
  Appstore,
  NearcoinLogoText,
  Playstore,
  RightTriangle,
} from "assets"
import React from "react"
import classes from "styles/components/Footer.module.scss"

const list1 = ["Privacy Policy", "Terms and Conditions", "Content Policy"]
const list2 = [
  "Report Copyright Infrigement",
  "Community Guidelines",
  "Business Owner Portal",
]

export default function Footer() {
  return (
    <div className={classes.container}>
      <div className={classes.content}>
        <div className={classes.left}>
          <div className={[classes.section, classes.logoSec].join(" ")}>
            <NearcoinLogoText className={classes.logo} />
            <p className={classes.slogan}>Reinventing Local Economy</p>
          </div>
          <div className={[classes.section, classes.bulletSec].join(" ")}>
            {[list1, list2].map((list) => (
              <div className={classes.bullets}>
                {list.map((text) => (
                  <div className={classes.bullet}>
                    <RightTriangle />
                    <p>{text}</p>
                  </div>
                ))}
              </div>
            ))}
          </div>
        </div>
        <div className={classes.right}>
          <div className={[classes.section, classes.QR].join(" ")}>
            <AppQR />
            <p className={classes.scan}>Scan to download the App </p>
          </div>
          <div className={[classes.section, classes.download].join(" ")}>
            <Appstore />
            <Playstore />
          </div>
        </div>
      </div>
      <div className={classes.copyright}>
        <p>Copyright © 2021 Nearcast, Inc.</p>
      </div>
    </div>
  )
}
