import React from "react"
import classes from "styles/components/Button.module.scss"

interface Props
  extends React.DetailedHTMLProps<
    React.ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  > {
  variant?: "filled" | "outlined"
  theme?: "primary" | "secondary"
}

export default function Button({
  theme = "primary",
  variant = "filled",
  className,
  ...props
}: Props) {
  return (
    <button
      className={[
        classes.container,
        classes[variant],
        classes[theme],
        className,
      ].join(" ")}
      {...props}
    />
  )
}
