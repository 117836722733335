// ABIs

// TYPES
import { AllowedChainConfig, ContractConfig } from "interfaces"

// WEB3
export const allowedChains: AllowedChainConfig[] = []
export const contractAddress = {}
export const contracts: ContractConfig[] = []
export interface ContractInstances {}
