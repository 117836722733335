import React from "react"
import classes from "styles/components/Header.module.scss"

import { NearcoinLogoText } from "assets"
import Button from "./Button"
import { useWallet } from "context/wallet"
import useScrollPosition from "hooks/useScrollPosition"

export default function Header() {
  const { account, connect, disconnect } = useWallet()
  const position = useScrollPosition()
  return (
    <header
      className={[
        classes.container,
        position > 0 ? classes.scrolled : classes.top,
      ].join(" ")}
    >
      <NearcoinLogoText className={classes.logo} />
      <div className={classes.buttons}>
        <Button theme="secondary">Buy Nearcoins</Button>
        <Button onClick={account ? disconnect : connect} variant="outlined">
          {account ? "Logout" : "Login"}
        </Button>
      </div>
    </header>
  )
}
