import React from "react"
import { Route, Routes } from "react-router-dom"

import Landing from "pages/Landing"
import Header from "components/Header"
import Footer from "components/Footer"
import { toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"

toast.configure({ theme: "colored" })

export default function App() {
  return (
    <div className="App">
      <Header />
      <Routes>
        <Route path="/" element={<Landing />} />
      </Routes>
      <Footer />
    </div>
  )
}
