import { HeroPhone1, HeroPhone2 } from "assets"
import useWindowDimensions from "hooks/useWindowDimensions"
import React, { useCallback, useMemo } from "react"
import { Parallax } from "react-scroll-parallax"
import classes from "styles/components/Hero.module.scss"
import Button from "./Button"

export default function Hero() {
  const { height: windowHeight } = useWindowDimensions()
  const parallaxFactor = useMemo(() => windowHeight / 1000, [windowHeight])
  const calcDeviation = useCallback(
    (eff: number) => eff - Math.abs(eff) / parallaxFactor,
    [parallaxFactor]
  )
  console.log({ parallaxFactor })
  return (
    <div className={classes.container}>
      <div className={classes.left}>
        <div className={classes.text}>
          <h1>Buy Local. Shop Local.</h1>
          <h2>Support Local Business, Create a Wallet today</h2>
        </div>
        <form className={classes.form}>
          <input type="text" name="address" placeholder="Street Address" />
          <input type="text" name="phone" placeholder="Phone Number" />
          <Button theme="secondary">Create my wallet</Button>
        </form>
      </div>
      <div
        className={classes.right}
        style={{
          marginTop: 100 / parallaxFactor,
        }}
      >
        <Parallax
          className={classes.phone}
          y={[-10, 10].map(calcDeviation)}
          tagOuter="figure"
        >
          <HeroPhone1 />
        </Parallax>
        <Parallax
          className={classes.phone}
          y={[20, -20].map(calcDeviation)}
          tagOuter="figure"
        >
          <HeroPhone2 />
        </Parallax>
      </div>
    </div>
  )
}
