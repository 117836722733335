import React from "react"
import classes from "styles/components/Feature.module.scss"

interface Props {
  Icon: React.FunctionComponent<
    React.SVGProps<SVGSVGElement> & {
      title?: string | undefined
    }
  >
  title: string
  description: string
}

export default function Feature({ Icon, title, description }: Props) {
  return (
    <div className={classes.container}>
      <Icon />
      <div className={classes.text}>
        <h3>{title}</h3>
        <p>{description}</p>
      </div>
    </div>
  )
}
