import React from "react"

import Hero from "components/Hero"
import Features from "components/Features"
import EarnCoins from "components/EarnCoins"

export default function Landing() {
  return (
    <div>
      <Hero />
      <Features />
      <EarnCoins />
    </div>
  )
}
