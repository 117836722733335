import React from "react"
import ReactDOM from "react-dom"
import "./styles/index.scss"
import App from "./App"
import { BrowserRouter } from "react-router-dom"
import { ParallaxProvider } from "react-scroll-parallax"
import { WalletProvider } from "context/wallet"

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <ParallaxProvider>
        <WalletProvider>
          <App />
        </WalletProvider>
      </ParallaxProvider>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById("root")
)
