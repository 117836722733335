import { DetailedHTMLProps, ImgHTMLAttributes } from "react"

// IMPORT SVGs
import { ReactComponent as Business } from "./business.svg"
import { ReactComponent as School } from "./school.svg"
import { ReactComponent as Cash } from "./cash.svg"
import { ReactComponent as Earn } from "./earn.svg"
import { ReactComponent as RightTriangle } from "./right-triangle.svg"
import { ReactComponent as AppQR } from "./appQR.svg"
import { ReactComponent as Playstore } from "./playstore.svg"
import { ReactComponent as Appstore } from "./appstore.svg"

// IMPORT IMAGES
import logoText from "./logo-text.png"
import heroPhone1 from "./hero-phone-1.png"
import heroPhone2 from "./hero-phone-2.png"
import coins from "./coins.png"

type ImgProps = DetailedHTMLProps<
  ImgHTMLAttributes<HTMLImageElement>,
  HTMLImageElement
>

export const createImage =
  (image: string, options: ImgProps = {}) =>
  (props: ImgProps) =>
    <img {...options} {...props} src={image} />

// EXPORT SVG COMPONENTS
export {
  Business,
  School,
  Cash,
  Earn,
  RightTriangle,
  AppQR,
  Appstore,
  Playstore,
}

// EXPORT IMG COMPONENTS
export const NearcoinLogoText = createImage(logoText)
export const HeroPhone1 = createImage(heroPhone1)
export const HeroPhone2 = createImage(heroPhone2)
export const Coins = createImage(coins)
